import UiButton from 'components/UiButton';
import UiFlexItem from 'components/UiFlex';
import UiBox from 'components/UiPanel';
import UiText from 'components/UiText';
import {AppContext} from 'contexts/AppContext';
import {SessionContext} from 'contexts/SessionContext';
import {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {API, API_URL} from 'utils/api';
import {useFile} from '../../../hooks/useFile';
import {REDUCER_TRIGGER_ACTIONS} from '../../../reducers/triggers';

const LogoConfig = () => {
  const {appConfigState, triggerDispatch} = useContext(AppContext);
  const {sessionState, headers: headersReq} = useContext(SessionContext);

  const [newLogo, setNewLogo] = useState('');
  const [isPickingLogo, setPickingLogo] = useState(false);
  const [sendingFile, setSendingFile] = useState(false);
  const [newLogoFile, setNewLogoFile] = useState<any>();

  const files = useFile(isPickingLogo, '.png,.jpg,.jpeg');

  useEffect(() => {
    const setLogoTmp = (f: Blob) => {
      const r = new FileReader();
      r.onload = e => {
        if (e.target?.result) {
          setNewLogo(e.target.result as string);
        }
      };
      r.readAsDataURL(f);
    };

    if (files?.length) {
      setLogoTmp(files[0] as Blob);
      setNewLogoFile(files[0] as Blob);
      setPickingLogo(false);
    }
  }, [files]);

  const handleSaveLogoInfos = async () => {
    try {
      if (newLogoFile) {
        setSendingFile(true);
        const formData = new FormData();
        formData.append('file', newLogoFile);
        await API.overwriteFile(
          formData,
          `/logos/${appConfigState.idCidadeSelecionada}.png`,
          appConfigState.idCidadeSelecionada,
          undefined,
          headersReq
        );
        triggerDispatch({type: REDUCER_TRIGGER_ACTIONS.RELOAD_LOGO});
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSendingFile(false);
    }
  };

  return (
    <>
      <UiBox width={'700px'}>
        <UiFlexItem direction="column">
          <UiText bold>Logo</UiText>

          <UiFlexItem direction="column">
            {appConfigState.idCidadeSelecionada && (
              <Logo
                src={
                  newLogo || `${API_URL}/cidadeconfig/${appConfigState.idCidadeSelecionada}/logo`
                }
                onClick={() => setPickingLogo(true)}
              />
            )}
          </UiFlexItem>
          <UiFlexItem justifyContent="flex-end" margin="10px 10px 0 0" width={'calc(100% - 10px)'}>
            <UiButton
              primary
              onClick={handleSaveLogoInfos}
              small
              disabled={!sessionState.usuario?.permissoes?.configureLogo || !newLogo || sendingFile}
            >
              Salvar
            </UiButton>
          </UiFlexItem>
        </UiFlexItem>
      </UiBox>
    </>
  );
};

const Logo: any = styled.img`
  border: 1px solid #eee;
  cursor: pointer;
  height: 64px;
  width: 64px;
  margin: auto;
`;

export default LogoConfig;
